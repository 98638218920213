@import '../styles/utilities';

.lightEditor {
  width: 100%;
  height: 100%;
  position: relative;
}

.editorWrap {
  position: relative;
  height: 100%;
  width: 100%;

  .canvas3d {
    height: 100%;
    width: 100%;

    canvas {
      height: 100% !important;
      width: 100% !important;
    }
  }
}

.controlPanel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  pointer-events: none;
  top: 0px;
  z-index: 10;
}

.body {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.projectSettings {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 30px;

  .objectExplorer {
    display: flex;
    align-items: center;
    pointer-events: none;
    height: 100%;
  }

  .right {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: flex-end;

  .materials,
  .toolsSelect {
    pointer-events: auto;
    width: fit-content;
  }
}

.bottomButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  padding-right: 30px;

  Button {
    height: 7.5vh;
    max-height: 80px;
    border-radius: 20px;

    background-color: rgba(white, 0.1);
    backdrop-filter: blur(30px);
    border: none;

    pointer-events: auto;
  }

  .save {
    width: 7.5vh;

    svg {
      height: 50%;
      width: 50%;
    }
  }
}

.playAnimation {
  width: 300px;
  padding: 24px 0px;

  .buttonContent {
    display: flex;
    column-gap: 15px;
    align-items: center;
    justify-content: center;

    font-size: 24px;
    color: white;
    font-weight: bold;
    text-transform: none;
  }

  .play .icon {
    fill: #3eb75f;
  }

  .pause .icon {
    fill: #adadad;
  }
}

.animationInfo {
  height: 100%;
  width: 0px;
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.4s ease-in-out;
  pointer-events: auto;
  backdrop-filter: blur(15px);
  border: 1px rgba(255, 255, 255, 0.15) solid;

  &.open {
    width: 550px;
  }
}

.scenarioStepsWrap {
  min-height: 140px;
  bottom: 0;
  pointer-events: auto;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px rgba(255, 255, 255, 0.15) solid;
  backdrop-filter: blur(15px);
  padding: 20px 30px 0px 30px;
}

.editorToolbar {
  width: calc(100% - 15px);
  left: 10px;
  position: absolute;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

canvas {
  outline: none;
}

.disabled {
  cursor: not-allowed;

  * {
    pointer-events: none;
  }
}

.ThumbnailSceneWrap {
  height: 128px;
  width: 128px;
  display: none;
}

.ToolThumbSceneWrap {
  height: 1024px;
  width: 1024px;
  display: none;
}
