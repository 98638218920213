$active-color: rgba(248, 183, 39, 0.8);
$not-active-color: rgb(202, 202, 202);
$border: 1px rgba(255, 255, 255, 0.4) solid !important;

.ql-toolbar {
  background: rgba(0, 0, 0, 0.4);
  border: $border;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ql-container {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: $border;
  overflow-y: auto;
}

.ql-editor {
  background: rgba(255, 255, 255, 0.75);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  &::-webkit-scrollbar {
    cursor: pointer;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
}

.ql-bold > .ql-active {
  color: $active-color;
}

.ql-snow.ql-toolbar {
  .ql-picker-label,
  .ql-picker-item {
    &:hover,
    &.ql-active,
    &.ql-selected {
      color: $active-color;

      .ql-stroke,
      .ql-stroke-miter {
        stroke: $active-color;
      }

      .ql-stroke.ql-fill,
      .ql-fill {
        fill: $active-color;
      }
    }
  }

  button {
    &.ql-active {
      color: $active-color;

      .ql-stroke,
      .ql-stroke-miter {
        stroke: $active-color;
      }

      .ql-fill,
      .ql-stroke.ql-fill {
        fill: $active-color;
      }
    }

    &:focus,
    &:hover {
      color: $active-color;

      .ql-stroke-miter,
      .ql-stroke {
        stroke: $active-color;
      }

      .ql-fill,
      .ql-stroke.ql-fill {
        fill: $active-color;
      }
    }
  }
}

.ql-snow {
  .ql-stroke,
  .ql-stroke-miter {
    stroke: $not-active-color;
  }

  .ql-fill {
    fill: $not-active-color;
  }

  .ql-toolbar,
  &.ql-toolbar {
    color: $not-active-color;

    .ql-picker-label {
      color: $not-active-color;
    }
  }
}

.ReactCodeMirror {
  height: 100%;
}

.CodeMirror {
  border-radius: 10px;
  border: $border;
  text-align: left;
  height: 100%;

  .CodeMirror-simplescroll-horizontal {
    height: 10px !important;

    div {
      width: 100%;
    }
  }

  .CodeMirror-simplescroll-vertical {
    width: 10px !important;

    div {
      height: 100%;
    }
  }

  .CodeMirror-simplescroll-horizontal,
  .CodeMirror-simplescroll-vertical {
    background-color: transparent;

    div {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 5px;
      border: none;
    }
  }

  .CodeMirror-scrollbar-filler {
    background-color: transparent;
  }
}
